
.box-icon {
    width: 90px;
    height: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    float: left;
    color: white;
}

.box-content {
    padding: 5px 10px;
    margin-left: 90px;
    
    p {
        margin: 0;
    }
    
    > p.box-content-title {
        text-transform: uppercase;
    }
    
    > p.box-content-number {
        font-weight: bold;
        font-size: 18px;
    }
}

::v-deep {
    .el-card__body {
        padding: 0;
    }
}
